import React, { useEffect, useState } from "react";
import { CButton, Loading } from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import { resendEmailVerification, verifyEmail } from "../Services/Auth";
import { Fade, Form, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { validateResendEmailVerificationForm } from "./Validations";
import { toast } from "react-toastify";
import { urlPrefix } from "../Helpers/Common";

const VerifyEmail = () => {
  const navigate = useNavigate()
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [emailVerification, setEmailVerification] = useState("pending");

  const verifyUserEmail = async (token) => {
    let result = await verifyEmail(token);
    await new Promise(resolve => setTimeout(resolve, 2000));
    result?.success ? setEmailVerification("passed") : setEmailVerification("passed");
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validate: validateResendEmailVerificationForm,
    onSubmit: async (values) => {
      setLoading(true)
      let result = await resendEmailVerification(values);
      setLoading(false);
      result?.success ? toast.success(result.message) : toast.error(result.error.message)
    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }

  useEffect(() => {
    verifyUserEmail(token);
  }, [])

  return (
    <React.Fragment>
      <div className="sign-in-form-wrap">
        {
          ["failed","pending"].includes(emailVerification) ? 
          <div className="verify-email">
          <div className="login-main-wrap forgot">
            <h4 className="heading-h4 login-subtitle">{emailVerification === "pending" ? "Please wait while we verify your email..." : null }</h4>
          </div>
          <div className="sign-form-main very-fy-mail">
            { emailVerification === "failed" ?
              <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label> Email verification failed. Please try again.</Form.Label>
                    <div className="inp-msg">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder="Type Email Here"
                      className="form-controla"
                      autoComplete="new-password"
                    />
                    {formik.errors.email ? errorElement(formik.errors.email) : null}
                    </div>
                  </FormGroup>
                </div>
                <div className="submit-cta">
                  <CButton label="Resend Email" custClassName="login-button t-btn w-100"></CButton>
                </div>
              </Form> : null
            }
          </div>
        </div> : null
        }
        {
          emailVerification === "passed" ?
            <div className="thanku-page-main">
              <div className="icon-thumb">
                <img src={urlPrefix() + "/assets/image/Icon-thumbs-up.png"} alt="thumb up"></img>
              </div>
              <h2 className="heading-h2 thanku-title"><span>Thank you for registering</span> <span>with House of Books</span></h2>
              <h5 className="heading-h5 thanku-subtile">Please log in using the newly created credentials.</h5>
              <div className="cta">
                <CButton redirectTo="login" label="Login" custClassName="cost-btn t-btn"></CButton>
              </div>
            </div> : null
        }
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};

export default VerifyEmail;